import Api from '../../../common/services/api';

export const SOCIAL_DELETE_COMMENT = 'social/DELETE_COMMENT';

export default (commentId, messageId) => async (dispatch) => {
  Api.delete(`/v1/comments/${commentId}`);

  return dispatch({
    type: SOCIAL_DELETE_COMMENT,
    commentId,
    messageId,
  });
};
