import Api from '../../../common/services/api';

export const SOCIAL_POST_COMMENT = 'social/POST_COMMENT';

export default (messageId, values, workflowId) => async (dispatch) => {
  const payload = {
    text: values.text,
    files: values.attachment ? [values.attachment.id] : undefined,
  };

  const { data: comment } = await Api.post(`/v2/messages/${messageId}/comments`, payload);

  dispatch({ type: SOCIAL_POST_COMMENT, messageId, comment, workflowId });
};
