import * as React from 'react';
import * as R from 'ramda';
import { Trans, withTranslation } from 'react-i18next';
import ConfettiBlock from '../../../../../../common/components/confetti-block';
import Dropdown from '../../../../../../common/components/dropdown';
import Icon from '../../../../../../common/components/icon';
import ConfirmButton from '../../../../../../common/components/confirm-button';
import Text from '../../../../../../common/components/text';
import EditForm from '../../../../forms/edit-message';

class WelcomeMessage extends React.Component {
  constructor(props) {
    super();

    this.state = {
      editing: false,
    };

    this.handleEdit = () => this.setState({ editing: true });
    this.handleCloseForm = () => this.setState({ editing: false });
    this.handleDelete = () => props.onDelete(props.item.id);
  }

  static props;

  createContent() {
    const { editing } = this.state;
    const { item } = this.props;

    if (editing) {
      return (
        <EditForm
          form={`edit-message/${item.id}`}
          message={item}
          onClose={this.handleCloseForm}
        />
      );
    }

    return R.is(String, item.text) && <Text>{`"${item.text}"`}</Text>;
  }

  render() {
    const { item, t } = this.props;

    return (
      <div className="Message__Inner IntroductionMessage">
        {(item.actions && (item.actions.can_edit || item.actions.can_delete)) && (
          <div className="pull-right">
            <Dropdown
              alignRight
              id={`Message__Actions-${item.id}`}
              toggle={<Icon type="more_vert" size="small" />}
            >
              {item.actions.can_edit && (
                <Dropdown.Item onClick={this.handleEdit}>
                  <Trans i18nKey="social:feed_item_welcome_change_text" />
                </Dropdown.Item>
              )}
              {item.actions.can_delete && (
                <ConfirmButton
                  danger
                  title={t('social:feed_item_message_welcome_confirm_remove_title')}
                  description={t('social:feed_item_message_welcome_remove_description')}
                  onConfirm={this.handleDelete}
                  confirmText={t('social:feed_item_message_default_confirm_remove')}
                >
                  <Dropdown.Item danger><Trans i18nKey="social:feed_item_message_welcome_remove" /></Dropdown.Item>
                </ConfirmButton>
              )}
            </Dropdown>
          </div>
        )}
        <ConfettiBlock title={t('social:feed_item_message_welcome_new_colleague')} user={item.user} />
        <div className="Message__content fs-exclude">
          {this.createContent()}
        </div>
      </div>
    );
  }
}

export default withTranslation()(WelcomeMessage);
