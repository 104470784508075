import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { MentionsInput, Mention } from 'react-mentions';
import { Observable } from 'rxjs/Observable';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import * as usersSelector from '../../../../../modules/core/selectors/users';
import { fetchMentions } from '../../utils/fetch-mentions';

class MentionsInputComponent extends Component {
  static propTypes = {
    placeholder: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired,
    networkId: PropTypes.string.isRequired,
    loggedUserId: PropTypes.string.isRequired,
    onEnter: PropTypes.func,
    className: PropTypes.string,
    teamId: PropTypes.string,
    defaultFocus: PropTypes.bool,
    users: PropTypes.array,
    inputRef: PropTypes.func,
    onPaste: PropTypes.func,
  };

  constructor(props) {
    super();

    this.setReference = (ref) => {
      this.input = ref;

      if (props.inputRef) props.inputRef(ref);

      // Set focus on input
      try {
        if (props.defaultFocus) ref.wrappedInstance.refs.input.focus();
      } catch (err) {
        // Ref not found
      }
    };
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.transformMention = this.transformMention.bind(this);

    this.state = {
      added: props.users,
      matches: [],
      value: props.input && props.input.value || '',
    };

    this.search$ = null;
  }

  componentDidMount() {
    Observable
      .create((observer) => (this.search$ = observer))
      .debounceTime(250)
      .filter((search) => search.length > 0 && search.length <= 20)
      .map((search) => fetchMentions(search, this.props.target))
      .subscribe(async (promise) => {
        const { data } = await promise;

        const result = data.filter((user) => user.id !== this.props.loggedUserId);

        this.state.matches = result;

        if (this.cb) this.cb(result);
      });
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (this.props.input.value !== nextProps.input.value && nextProps.input.value !== nextState.value) {
      // Update value when it's changed through redux but no the onChange handler
      // eslint-disable-next-line react/no-will-update-set-state
      this.setState({ value: nextProps.input.value });
    }
  }

  handleKeyPress(e) {
    const { onEnter } = this.props;

    if (e.charCode === 13 && !e.shiftKey && onEnter) {
      onEnter();
      e.preventDefault();
    }
  }

  handleChange(e) {
    const { onChange } = this.props.input;
    const { value } = e.target;

    this.setState({ value });

    onChange(value);
  }

  handleSearch(query, cb) {
    this.cb = cb;

    this.search$.next(query);
  }

  handleAdd(id) {
    const { addUserToStore } = this.props;
    const { added } = this.state;

    const user = R.find(R.propEq('id', id), this.state.matches);

    addUserToStore(user);

    this.setState({ added: R.assoc(id, user, added) });
  }

  transformMention(id) {
    const user = this.state.added[id] || R.find(R.propEq('id', id), this.state.matches);

    if (!user) return `@(${id})`;

    return user.full_name;
  }

  render() {
    const { value } = this.state;
    const { placeholder } = this.props;

    return (
      <MentionsInput
        ref={this.setReference}
        allowSpaceInQuery
        className="Form__control"
        markup="@(__id__)"
        value={value}
        placeholder={placeholder}
        onChange={this.handleChange}
        onKeyPress={this.handleKeyPress}
        onPaste={this.props.onPaste}
        displayTransform={this.transformMention}
      >
        <Mention
          appendSpaceOnAdd
          trigger="@"
          data={this.handleSearch}
          renderSuggestion={R.prop('full_name')}
          onAdd={this.handleAdd}
        />
      </MentionsInput>
    );
  }
}

const mapStateToProps = () => {
  const selector = usersSelector.byIds();

  return (state, props) => ({
    loggedUserId: state.loggedUser.user.id,
    networkId: state.network.selected,
    users: selector(state, props.input.value),
  });
};

const mapDispatchToProps = {
  addUserToStore: (user) => ({
    type: 'common/ADD_USER_TO_STORE', // This type is not actually used
    related: {
      users: [user],
    },
  }),
};

const MentionsWrapper = connect(mapStateToProps, mapDispatchToProps)(MentionsInputComponent);

export default (props) => <Field {...props} component={MentionsWrapper} />;
