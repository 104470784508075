import React, { memo } from 'react';
import { Trans } from 'react-i18next';
import AsyncList from '@common/components/list/async';
import Comment from '../../comment';
import { AsyncListCursorFetchHandler } from '@common/components/list';
import { Comment as CommentType, Discussion } from '@modules/social/types/objects';
import { LoggedUser } from '@common/types/objects';
import ShowMoreComments from './show-more-comments';

type CommentsListProps = {
  comments?: CommentType[] | null;
  getComments: AsyncListCursorFetchHandler<CommentType, { id: string }>;
  loggedUser: LoggedUser;
  onDelete: (commentId: string, messageId: string) => Promise<void>;
  onHighFive: (commentId: string) => Promise<void>;
  discussion: Discussion;
};

const CommentsList = memo(({
  comments, getComments, loggedUser, onDelete, onHighFive, discussion
}: CommentsListProps) => {

  return (
    <AsyncList
      order="DESC"
      initialListSize={3}
      items={comments || []}
      // @ts-expect-error
      data={{
        onFetch: getComments,
        filter: {
          id: discussion.id,
        },
      }}
      renderRow={Comment}
      rowProps={{
        loggedUser,
        onDelete,
        onHighFive,
        message: discussion,
      }}
      placeholder={(
        <Trans i18nKey="social:feed_item_no_comments" />
      )}
      ShowMoreComponent={ShowMoreComments}
    />
  );
});

export default CommentsList;
