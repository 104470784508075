import Api from '../../../common/services/api';

export const SOCIAL_POST_CONGRATULATION = 'social/POST_CONGRATULATION';

export default (date, text) => async (dispatch, getState) => {
  const { network: { selected } } = getState();

  const { data } = await Api.post(`/v1/networks/${selected}/birthdays/${date}/comments`, { text });

  dispatch({
    type: SOCIAL_POST_CONGRATULATION,
    messageId: date,
    comment: data,
  });
};
