import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ImageItem from '../image-item/large';
import Popover from './popover';

const defaultUser = { full_name: '"Verwijderde gebruiker"', profile_img: '' };

class Profile extends PureComponent {
  static propTypes = {
    user: PropTypes.object.isRequired,
    children: PropTypes.any,
    className: PropTypes.string,
    meta: PropTypes.any,
    size: PropTypes.number,
    enablePopover: PropTypes.bool,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    enablePopover: false,
  };

  render() {
    const {
      children, className, size, meta, enablePopover, onClick,
    } = this.props;

    const user = this.props.user || defaultUser;
    const metaText = typeof meta === 'string' ? <small>{meta}</small> : meta || null;

    let _className = `Profile${className ? ` ${className}` : ''}`;
    if (onClick) {
      _className += ' pointer';
    }

    return (
      <ImageItem
        mask
        className={_className}
        image={user.profile_img}
        name={user.full_name}
        meta={metaText}
        imageSize={size}
        ImageWrapper={enablePopover ? Popover : null}
        wrapperProps={{ user }}
        onClick={onClick}
      >
        {children}
      </ImageItem>
    );
  }
}

export default Profile;
