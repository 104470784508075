import React from 'react';
import { omit } from 'lodash';
import { UseControllerProps, useController } from 'react-hook-form';
import { BaseFieldProps, Field, WrappedFieldProps } from 'redux-form';

import { TimePickerInput, TimePickerInputOwnProps } from './time-picker-input';

/**
 * By default the form validation mode is set to `mode: 'onSubmit'`.
 * This means the validation will be shown after submitting the form.
 * You probably want to set this option to `all` to have immediate feedback when a field does not pass the added rules.
 * useForm({ mode: 'all' });
 */
export const TimePickerInputHookForm = (props: TimePickerInputOwnProps & UseControllerProps) => {
  const { field, fieldState } = useController(props);
  return (
    <TimePickerInput
      {...props}
      {...omit(field, ['ref'])}
      error={fieldState.error?.message || fieldState.error?.message === ''}
    />
  );
};

const ReduxFormField = (props: WrappedFieldProps & TimePickerInputOwnProps) => {
  return (
    <TimePickerInput
      error={props.meta.touched && props.meta.error}
      warning={props.meta.touched && props.meta.warning}
      {...props.input}
      {...omit(props, ['input', 'meta'])}
    />
  );
};

const TimePickerInputField = ({ name, ...props }: BaseFieldProps & TimePickerInputOwnProps) => (
  <Field
    id={name}
    name={name}
    component={ReduxFormField}
    props={props}
  />
);

export default TimePickerInputField;
