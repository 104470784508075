import * as React from 'react';
import ProfileImage from '../profile-image';
import ImageItem from '../image-item';

import type { SimpleUser } from '../../types/objects';

export type Props = {
  title: string,
  user?: SimpleUser,
  users?: Array<SimpleUser>,
};

const ConfettiBlock = ({ title, user: profile, users }: Props) => (
  <div className="ConfettiBlock">
    <div className="ConfettiBlock__Header">
      <h2>{title}</h2>
    </div>
    {profile && (
      <div className="ConfettiBlock__Profile">
        <ProfileImage user={profile} size={80} />
        <div className="ConfettiBlock__Profile__Icon">🎉</div>
        <div className="ConfettiBlock__Profile__Name fs-exclude">{profile.full_name}</div>
      </div>
    )}
    <div className="ConfettiBlock__Users fs-exclude">
      <div className="ConfettiBlock__Users__Inner">
        {users && users.map((user) => (
          <ImageItem key={user.id} image={user.profile_img} name={user.full_name} />
        ))}
      </div>
    </div>
  </div>
);

export default ConfettiBlock;
