import Api from '../../../common/services/api';
import * as reducer from '../reducers/messages';

export const SOCIAL_MESSAGE_HIGH_FIVE = 'social/HIGH_FIVE_MESSAGE/add';
export const SOCIAL_MESSAGE_UNHIGH_FIVE = 'social/HIGH_FIVE_MESSAGE/remove';

export default (messageId, workflowId) => (dispatch, getState) => {
  const state = getState();
  const message = reducer.findById(state, messageId);

  if (!message) return false;

  Api.put(`/v1/messages/${message.id}/like`);

  return dispatch({
    type: message.has_liked ? SOCIAL_MESSAGE_UNHIGH_FIVE : SOCIAL_MESSAGE_HIGH_FIVE,
    messageId: message.id,
    workflowId,
  });
};
