import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';

import * as alert from '@common/services/alert';
import File from '@common/components/form/inputs/file/file';
import Mentions from '@common/components/form/inputs/mentions/mentions';
import ImagePreview from '@common/components/file-preview/image';
import Icon from '@common/components/icon';
import Permission from '@common/components/permission';
import GifInput from '@common/components/form/inputs/gif';

import { getFilesFromClipboard } from '@common/utils/get-files-from-clipboard';
import { setFormFileAttachments } from '@common/components/form/utils';
import { IMAGES_ACCEPT } from '@common/components/form/inputs/file/utils';
import { useAppSelector, useFormValues } from '@common/hooks';
import validate from '../validators/comment';
import { selected } from '@modules/organisation/selectors/organisation';
import postComment from '../../actions/post-comment';
import postCongratulation from '../../actions/post-congratulation';

import { EMessageTypes } from '../../definitions';
import { EComponentTypes } from '@common/definitions';
import type { Attachment, FileInProgress } from '@common/types/objects';
import type { Message } from '../../types/objects';

type FormData = {
  text: string;
  attachment: Attachment & FileInProgress | null;
};

type PostCommentFormOwnProps = InjectedFormProps<FormData> & {
  message: Message;
};

const PostCommentForm = ({
  message, form, submitting, valid, reset, change, handleSubmit,
}: PostCommentFormOwnProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const organisationId = useAppSelector(selected).id;
  const values = useFormValues<FormData>(form);

  const onAddImageFromClipboard = useCallback(async (e: ClipboardEvent) => {
    if (values.attachment) return;
    const [file] = getFilesFromClipboard(e);
    if (file) {
      e.preventDefault();
      setFormFileAttachments(
        [file],
        values.attachment,
        organisationId,
        (newValue) => change('attachment', newValue),
        t,
        { processFile: true },
      );
    }
  }, [organisationId, values, t, change]);

  const onSubmit = useCallback(async (newValues) => {
    try {
      if (message.message_type === EMessageTypes.BIRTHDAY_MESSAGE) {
        await dispatch(postCongratulation(message.id, newValues.text));
      } else {
        await dispatch(postComment(message.id, newValues, message.workflow_id));
      }
      reset();
    } catch (response: any) {
      alert.forStatus(response.status_code, {
        warning: t('social:form_post_comment_warning_posting_comment'),
        error: t('social:form_post_comment_error_posting_comment'),
      });
    }
  }, [message, reset, t, dispatch]);

  return (
    <form className="Form PostComment" onSubmit={handleSubmit(onSubmit)}>
      {values.attachment && (
        <div className="PostComment__Attachment">
          <ImagePreview
            key={values.attachment.id}
            file={values.attachment}
            size={70}
            onRemove={() => change('attachment', null)}
          />
        </div>
      )}
      <div className="PostComment__Container">
        <File
          processFile
          disabled={!!values.attachment}
          name="attachment"
          accept={IMAGES_ACCEPT}
          maxFileSize={25}
        >
          <Icon type="image__filled" className="PostComment__Action" />
        </File>
        <Permission component={EComponentTypes.GIPHY}>
          <GifInput name="attachment" disabled={!!values.attachment}>
            <Icon type="gif" className="PostComment__Action" />
          </GifInput>
        </Permission>
        <Mentions
          defaultFocus
          name="text"
          placeholder={t('social:form_post_comment_text_placeholder')}
          target={message.created_in}
          onEnter={handleSubmit(onSubmit)}
          onPaste={onAddImageFromClipboard}
        />
        <button disabled={!valid || submitting}>
          <Icon type="send__filled" className="PostComment__SentButton" />
        </button>
      </div>
    </form>
  );
};

export default reduxForm<FormData, PostCommentFormOwnProps>({
  form: 'post-comment',
  validate,
  initialValues: {
    text: '',
    attachment: null,
  },
})(PostCommentForm);
