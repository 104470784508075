import * as R from 'ramda';
import Api from '../../../common/services/api';

export default (id) => Api.get(`/v2/messages/${id}/likes`)
  .then((response) => ({
    data: R.map((highfive) => R.find(R.propEq('id', highfive.user_id), response.meta.related.users), response.data),
    meta: {
      pagination: {
        total_count: response.data.length,
        offset: 0,
        limit: response.data.length,
      },
    },
  }));
