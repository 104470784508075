import Api from '../../../common/services/api';
import { SOCIAL_RECEIVE_COMMENTS } from './index';

export default (_, filter) => async (dispatch) => {
  // in this case, id is the date
  const request = await Api.get(`/v2/messages/${filter.id}/comments`);

  dispatch({
    type: SOCIAL_RECEIVE_COMMENTS,
    messageId: filter.id,
    comments: request.data,
    related: request.meta ? request.meta.related : null,
  });

  return request;
};
