import * as React from 'react';
import { withTranslation } from 'react-i18next';
import * as AnalyticsService from '../../../../../common/services/analytics';
import Api from '../../../../../common/services/api';
import Modal from '../../../../../common/components/modal';
import AsyncList from '../../../../../common/components/list/async';
import ImageItem from '../../../../../common/components/image-item/user';

const { EEventNames } = AnalyticsService;

const presets = {
  seen: {
    api_key: 'seen',
    api_version: '2',
    key: 'seen_count',
  },
  read: {
    api_key: 'read',
    api_version: '1',
    key: 'reads_count',
  },
};

class MessageSeenModalComponent extends React.Component {
  static defaultProps = {
    filter: presets.seen.api_key,
  };

  constructor(props) {
    super();

    this.state = {
      tab: presets[props.filter].api_key,
      cache: {},
    };

    this.handleTabChange = (nextTab) => this.setState({ tab: nextTab });
    this.handleEnter = this.handleEnter.bind(this);
    this.handleRequest = this.handleRequest.bind(this);
    this.handleSetCache = this.handleSetCache.bind(this);
  }

  static props;

  async handleEnter() {
    const { message, filter } = this.props;

    if (message.not_seen_count !== undefined) return;

    const request = await this.handleRequest(0, { tab: `not_${presets[filter].api_key}` }, 1);

    if (message[`not_${presets[filter].key}`] !== request.meta.pagination.total_count) {
      this.props.onUpdate({ [`not_${presets[filter].key}`]: request.meta.pagination.total_count });
    }
  }

  async handleRequest(offset = 0, filter = {}, limit = 30) {
    const { message } = this.props;

    const query = Api.utils.toQuery({
      offset,
      limit,
    });

    return Api.get(`/v${presets[this.props.filter].api_version}/messages/${message.id}/${filter.tab}?${query}`);
  }

  handleSetCache(request, strategy, filter) {
    if (request.meta.pagination.offset > 0) return;

    if (!strategy) {
      AnalyticsService.track(filter.tab === 'seen' ? EEventNames.VIEWED_SEEN_USERS_LIST : EEventNames.VIEWED_NOT_SEEN_USERS_LIST);
    }

    this.setState({
      cache: {
        ...this.state.cache,
        [this.state.tab]: { items: request.data },
      },
    });
  }

  render() {
    const { tab, cache } = this.state;
    const { message, children, filter, t } = this.props;

    return (
      <Modal
        onEnter={this.handleEnter}
        header={(
          <Modal.TabHeader
            onChange={this.handleTabChange}
            active={tab}
            options={[{
              title: t('social:feed_item_message_seen_modal', { context: filter }),
              value: presets[filter].api_key,
              count: message[presets[filter].key],
            }, {
              title: t('social:feed_item_message_seen_modal_not', { context: filter }),
              value: `not_${presets[filter].api_key}`,
              count: message[`not_${presets[filter].key}`],
            }]}
          />
        )}
        content={(
          <AsyncList
            enableSearch={false}
            containerClassName="ItemsList"
            data={{
              onFetch: this.handleRequest,
              filter: {
                tab,
              },
              cache: tab && cache[tab] ? cache[tab] : {},
              onPostFetch: this.handleSetCache,
            }}
            renderRow={ImageItem}
            rowProps={{
              mask: true,
            }}
            placeholder={tab === 'seen'
              ? t('social:feed_item_message_seen_placeholder_seen')
              : t('social:feed_item_message_seen_placeholder_not_seen')}
          />
        )}
      >
        {children}
      </Modal>
    );
  }
}

export default withTranslation()(MessageSeenModalComponent);
