import React from 'react';
import { useTranslation } from 'react-i18next';
import ConfettiBlock from '../../../../../../common/components/confetti-block';
import type { SimpleUser } from '../../../../../../common/types/objects';

type Props = {
  users: SimpleUser[],
};

const BirthdayMessageContentComponent = ({ users }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="Message__Inner Message--type-birthday">
      <ConfettiBlock
        title={t('social:feed_item_message_birthdays_today')}
        users={users}
      />
    </div>
  );
};

export default BirthdayMessageContentComponent;
