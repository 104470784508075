import React from 'react';
import PropTypes from 'prop-types';
import * as AnalyticsService from '../../../../../common/services/analytics';
import Attachment from '../../../../../common/components/attachment';

const { EEventNames } = AnalyticsService;

const MessageFiles = ({ items, message }) => {
  if (items.length === 0) return null;

  return (
    <div className="Message__content">
      {items.map((item) => (
        <Attachment
          key={item.id}
          item={item}
          onOpen={() => AnalyticsService.track(EEventNames.OPENED_FILE_IN_TIMELINE, {
            messageId: message.id,
            type: AnalyticsService.capitalise(item.file_type),
            fileExtension: AnalyticsService.getExtension(item.path),
          })}
        />
      ))}
    </div>
  );
};

MessageFiles.propTypes = {
  items: PropTypes.array.isRequired,
  message: PropTypes.object.isRequired,
};

MessageFiles.defaultProps = {
  items: [],
};

export default MessageFiles;
