import Api from '../../../common/services/api';

export const SOCIAL_EDIT_MESSAGE = 'social/EDIT_MESSAGE';

export default (messageId, payload, scheduled = false) => async (dispatch) => {
  const { data: message } = await Api.put(`/v3/messages/${messageId}`, payload);

  return dispatch({
    type: SOCIAL_EDIT_MESSAGE,
    messageId,
    message,
    scheduled,
  });
};
