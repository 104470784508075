import * as React from 'react';
import * as R from 'ramda';
import { Trans, useTranslation } from 'react-i18next';
import Icon from '../../../../../common/components/icon';
import Modal from '../../../../../common/components/modal';
import AsyncList from '../../../../../common/components/list/async';
import ImageItem from '../../../../../common/components/image-item/user';
import SeenModal from './seen-modal';
import fetchHighFives from '../../../actions/fetch-high-fives';
import { EMessageTypes } from '../../../definitions';

const MessageInfo = ({
  message, onHighFive, onUpdate, toggleComments, disableActions,
}) => {
  const { t } = useTranslation();

  const classNames = ['Message__Info'];
  if (message.message_type === EMessageTypes.BIRTHDAY_MESSAGE) classNames.push('Message__Info--center');

  const highfivesClassName = ['Message__Info__Action', 'pull-left'];
  if (message.has_liked) highfivesClassName.push('Message__Info__Action--active');

  const workflowCreatedIn = (message.workflow_created_in && message.workflow_created_in.length > 0)
    ? R.intersperse(', ', R.pluck('name', message.workflow_created_in))
    : t('social:feed_item_message_info_all_employees');

  if (Array.isArray(workflowCreatedIn) && workflowCreatedIn.length >= 3) {
    workflowCreatedIn[workflowCreatedIn.length - 2] = ` ${t('social:feed_item_message_info_and')} `;
  }

  return (
    <div className={classNames.join(' ')} style={message.publish_at ? { opacity: 0.5, pointerEvents: 'none' } : undefined}>
      {message.workflow_created_in && (
        <div className="Message__Info__Counts">
          <small>
            <Trans i18nKey="social:feed_item_message_info_send_to" values={{ createdIn: workflowCreatedIn }} components={[<b>a</b>]} />
          </small>
        </div>
      )}

      {message.message_type !== EMessageTypes.BIRTHDAY_MESSAGE && (
        <div className="Message__Info__Counts">
          {!disableActions ? (
            <Modal
              title={t('social:feed_item_message_high_fives_modal_title')}
              content={(
                <AsyncList
                  containerClassName="ItemsList"
                  data={{
                    onFetch: () => fetchHighFives(message.id),
                  }}
                  renderRow={ImageItem}
                  placeholder={t('social:feed_item_message_high_fives_placeholder')}
                />
              )}
            >
              <small>
                <Trans i18nKey="social:feed_item_message_info_high_fives_count" values={{ count: message.likes_count }} />
              </small>
            </Modal>
          ) : (
            <small>
              <Trans i18nKey="social:feed_item_message_info_high_fives_count" values={{ count: message.likes_count }} />
            </small>
          )}
          {(message.can_comment || message.comments_count > 0) && (
            <small onClick={!disableActions ? toggleComments : null}>
              <Trans i18nKey="social:feed_item_message_info_comments_count" values={{ count: message.comments_count }} />
            </small>
          )}
          {!message.publish_at && message.actions.can_see_stats && (
            message.is_important ? (
              <SeenModal key="read" message={message} onUpdate={onUpdate} filter="read">
{/* eslint-disable-line react/jsx-indent */}
                <small className="pull-right Message__Info__Counts--green">
                  <Trans i18nKey="social:feed_item_message_info_read_count" values={{ count: message.reads_count }} />
                </small>
              </SeenModal>
            ) : (
              <SeenModal key="seen" message={message} onUpdate={onUpdate}>
{/* eslint-disable-line react/jsx-indent */}
                <small className="pull-right Message__Info__Counts--green">
                  <Trans i18nKey="social:feed_item_message_info_seen_count" values={{ count: message.seen_count }} />
                </small>
              </SeenModal>
            )
          )}
        </div>
      )}
      {message.message_type === EMessageTypes.BIRTHDAY_MESSAGE && message.comments_count > 0 && (
        <div className="Message__Info__Counts">
          <small>
            <Trans i18nKey="social:feed_item_message_info_congratulations_count" values={{ count: message.comments_count }} />
          </small>
        </div>
      )}

      {message.id && (
        <div className="Message__Info__Actions">
          {message.message_type !== EMessageTypes.BIRTHDAY_MESSAGE && (
            <div
              className={highfivesClassName.join(' ')}
              onClick={!disableActions ? () => onHighFive(message.id, message.workflow_id) : undefined}
            >
              <Icon type="highfive__filled" />
              <Trans i18nKey="social:feed_item_message_info_high_five" />
            </div>
          )}

          {message.message_type === EMessageTypes.BIRTHDAY_MESSAGE ? (
            <div className="Message__Info__Action pull-left">
              <img src="/static/images/High five icon@3x.png" alt={t('social:feed_item_message_info_congratulate')} />
              <Trans i18nKey="social:feed_item_message_info_congratulate" />
            </div>
          ) : (
            <div
              className={`Message__Info__Action${!message.can_comment ? ' Message__Info__Action--disabled' : ''} pull-left`}
              onClick={message.can_comment && !disableActions ? toggleComments : undefined}
            >
              <Icon type="chat__filled" />
              &nbsp;
              <Trans i18nKey="social:feed_item_message_info_comment" />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MessageInfo;
