import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PollOption from './option';

import { useAppSelector } from '@common/hooks';
import { SECOND } from '@common/definitions';
import { TPositionInTime, getDeadlineDateTimeLabel, getPositionInTime } from '@common/utils/date';
import { combineClassNames } from '@common/utils/combineClassNames';
import { Message, Poll, PollOption as TPollOption } from '@modules/social/types/objects';

type PollComponentOwnProps = {
  poll: Poll;
  message: Partial<Message>;
  onVote?: (pollId: string, vote: boolean, optionId: string, optionIds: string[], messageId?: string) => void;
};

const PollComponent = ({
  poll, message, onVote,
}: PollComponentOwnProps) => {
  const { t } = useTranslation();
  const locale = useAppSelector((state) => state.loggedUser.user.language.locale);
  const [expiresAtPositionInTime, setExpiresAtPositionInTime] = useState<TPositionInTime | undefined>(
    getPositionInTime(poll.expires_at)
  );

  const expired = expiresAtPositionInTime === TPositionInTime.PAST;
  const expiresAt = useMemo(() => {
    if (!poll.expires_at) return undefined;
    if (expired) return t('social:poll_expired');
    return t('social:poll_will_expire_at', {
      time: getDeadlineDateTimeLabel(poll.expires_at, locale, t)
    });
  }, [locale, poll.expires_at, expired, t]);

  // Check every second if the poll is expired.
  // Only check when the expiration date is set and
  // it is not in the past.
  useEffect(() => {
    if (!!expiresAtPositionInTime && expiresAtPositionInTime !== TPositionInTime.PAST) {
      const interval = () => {
        const newPositionInTime = getPositionInTime(poll.expires_at);
        if (newPositionInTime !== expiresAtPositionInTime) {
          setExpiresAtPositionInTime(newPositionInTime);
        }
      };
      const timer = setInterval(interval, SECOND);
      return () => clearInterval(timer);
    }
  }, [expiresAtPositionInTime, poll.expires_at, setExpiresAtPositionInTime]);

  // Currently voting relies on the redux state, because it is
  // tightly coupled with the message which is loaded when fetching the feed.
  // This should be addressed when removing the feed from the redux state.
  const handleVote = useCallback((option: TPollOption) => {
    if (onVote) {
      if (poll.vote_result?.includes(option.id)) {
        const optionIds = poll.vote_result?.filter((id) => id !== option.id) || [];
        onVote(poll.id, false, option.id, optionIds, poll.message_id);
      } else {
        const optionIds = poll.is_multiple_choice ? [...(poll.vote_result || []), option.id] : [option.id];
        onVote(poll.id, true, option.id, optionIds, poll.message_id);
      }
    }
  }, [poll, onVote]);

  const isVoted = !!poll.vote_result?.length;
  const showVotes = (isVoted || expired) && poll.show_votes;

  const getIsSelected = useCallback((optionId: string) => {
    return !!poll.vote_result?.includes(optionId);
  }, [poll.vote_result]);

  const getPercentage = useCallback((optionId: string, voteCount: number) => {
    if (showVotes || message.actions?.can_see_stats) {
      return (voteCount / poll.total_vote_count) * 100;
    }
    return isVoted && getIsSelected(optionId) ? 100 : 0;
  }, [poll.total_vote_count, showVotes, isVoted, message, getIsSelected]);

  return (
    <div className="Poll">
      <div className={combineClassNames('Poll__Question', {
          [`Poll__Question--${expiresAtPositionInTime}`]: !!expiresAtPositionInTime,
        })}
      >
        {poll.question}
      </div>
      {expiresAt && (
        <div className={`Poll__Deadline Poll__Deadline--${expiresAtPositionInTime}`}>
          {expiresAt}
        </div>
      )}
      {poll.is_multiple_choice && (
        <div className="Poll_MultipleAnswers">
          {t('social:poll_is_multiple_choice_description')}
        </div>
      )}
      <div>
        {poll.options.map((option) => (
          <PollOption
            key={option.id}
            item={option}
            pollId={poll.id}
            showVoters={message.actions?.can_see_stats}
            showVotes={showVotes}
            selected={getIsSelected(option.id)}
            percentage={getPercentage(option.id, option.vote_count)}
            onSelect={onVote ? () => handleVote(option) : undefined}
            disabled={expired}
          />
        ))}
      </div>
    </div>
  );
};

export default PollComponent;
