import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import * as AnalyticsService from '../../../../../common/services/analytics';
import Rows from '../../../../../common/components/rows';
import Attachment from '../../../../../common/components/attachment';

const { EEventNames } = AnalyticsService;

const MessageAttachments = ({ items, message }) => {
  if (items.length === 0) return null;

  const size = R.cond([
    [R.equals(1), () => ({
      className: 'Message__Attachments__Attachment--size-large',
      height: 500,
    })],
    [R.gt(R.__, 1), () => ({
      className: 'Message__Attachments__Attachment--size-medium',
      height: 350,
    })],
  ])(items.length);

  return (
    <Rows
      items={items}
      render={Attachment}
      itemsPerRow={2}
      rowProps={(item) => ({
        size,
        className: `Message__Attachments__Attachment ${size.className}`,
        maxHeight: size.height,
        onOpen: () => AnalyticsService.track(EEventNames.OPENED_FILE_IN_TIMELINE, {
          messageId: message.id,
          type: AnalyticsService.capitalise(item.file_type),
          fileExtension: AnalyticsService.getExtension(item.path),
        }),
      })}
      rowClassName="Message__content Message__Attachments"
    />
  );
};

MessageAttachments.propTypes = {
  items: PropTypes.array.isRequired,
  message: PropTypes.object.isRequired,
};

MessageAttachments.defaultProps = {
  items: [],
};

export default MessageAttachments;
