import PropTypes from 'prop-types';
import React from 'react';
import Sensor from 'react-visibility-sensor';

const VisibilitySensor = ({ children, active, onVisible, ...props }) => (
  <Sensor
    delayedCall
    partialVisibility="bottom"
    intervalDelay={5000}
    scrollDelay={500}
    onChange={(visible) => (visible ? onVisible() : false)}
    active={active}
    {...props}
  >
    {children}
  </Sensor>
);

VisibilitySensor.propTypes = {
  children: PropTypes.any.isRequired,
  onVisible: PropTypes.func,
  active: PropTypes.bool,
};

export default VisibilitySensor;
